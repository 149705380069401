/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          $(function(){

              $.lazyLoadXT.onload = function() {
                  var $el = $(this);
                  $el
                      .removeClass('lazy-hidden')
                      .addClass('animated ' + $el.attr('data-effect'));
              };


              var checkanim = function(){
                  var a =  0;

                  $(".main-anim:in-viewport").each(function() {
                      a++;
                    // console.log('inviewport');

                          //console.log('inviewport',this);
                          $(this).find('.anim-left').addClass('active');
                          $(this).find('.anim-right').addClass('active');
                          $(this).find('.anim-fade').addClass('active');


                  });
                  $(".main-anim:below-the-fold").each(function() {
                      a++;
                    // console.log('inviewport');

                          //console.log('inviewport',this);
                          $(this).find('.anim-left').removeClass('active');
                          $(this).find('.anim-right').removeClass('active');
                          $(this).find('.anim-fade').removeClass('active');


                  });


              };
             checkanim();

                  $(window).bind("scroll", function(event) {
                      checkanim();
                  });
              $(document).on( 'scroll', function(){

                  if ($(window).scrollTop() > 100) {
                      $('.scroll-top-wrapper').addClass('show');
                      $('.navbar-fixed-top').addClass('small-nav');
                  } else {
                      $('.scroll-top-wrapper').removeClass('show');
                      $('.navbar-fixed-top').removeClass('small-nav');
                  }
              });

              $('.scroll-top-wrapper').on('click', scrollToTop);
          });

          function scrollToTop() {
              verticalOffset = typeof(verticalOffset) !== 'undefined' ? verticalOffset : 0;
              element = $('body');
              offset = element.offset();
              offsetTop = offset.top;
              $('html, body').animate({scrollTop: offsetTop}, 500, 'linear');
          }

          /**
           * social popups
           * @param url
           * @param width
           * @param height
           */
          function windowPopup(url, width, height) {
              // Calculate the position of the popup so
              // it’s centered on the screen.
              var left = (screen.width / 2) - (width / 2),
                  top = (screen.height / 2) - (height / 2);

              window.open(
                  url,
                  "",
                  "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
              );
          }

          $(".js-social-share").on("click", function(e) {
              e.preventDefault();

              windowPopup($(this).attr("href"), 600, 400);
          });

// Vanilla JavaScript
          var jsSocialShares = document.querySelectorAll(".js-social-share");
          if (jsSocialShares) {
              [].forEach.call(jsSocialShares, function(anchor) {
                  anchor.addEventListener("click", function(e) {
                      e.preventDefault();

                      windowPopup(this.href, 500, 300);
                  });
              });
          }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
//console.log('js home init');



          var resizehome = function(){
              var wheight = $(window).height();
              $('.page-header-big-image').height(wheight);
          };
          $(window).resize(resizehome);
          resizehome();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'archive': {
      init: function() {
        // JavaScript to be fired on the about us page

          $('.item-link').hover( function(){
                 // console.log('hover',$(this).closest('.inner').children('.img-container'));
                  $(this).closest('.inner').children('.img-container').addClass('hover');

          },
          function(){
              $(this).closest('.inner').children('.img-container').removeClass('hover');
          });





      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
